/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "gatsby";
import StackItem from "./StackItem";
import { items } from "../../constants";
import { Carousel } from "antd";
import Slide from "./Projects/Slide";
import { projects } from "./Projects/data";

const AboutSection = ({ theme }) => {
  return (
    <React.Fragment>
      <section>
        <div className="container">
          <div style={{ display: "flex", padding: "48px 0" }}>
            <div className="text-intro">
              <h2>A propos</h2>
              <p>
                Je m&apos;appelle Kevin, je vis à Paris. Je suis développeur web, spécialisé sur
                React, React Native et Node.js. Sur ce site, vous trouverez mes dernières
                réalisations ainsi que mon blog, dans lequel j&apos;aborde des sujets liés à
                JavaScript en général et React en particulier. Si vous avez un projet dont vous
                aimeriez parler, vous êtes libre de{" "}
                <Link className="text-link" to="/contact">
                  me contacter
                </Link>
                .
              </p>
            </div>
            {/* <div style={{ flex: 1, backgroundColor: "rebeccapurple", borderRadius: 5 }} /> */}
          </div>
          <div className="stack-section">
            <h2>Les technos que j&apos;utilise</h2>
            <div className="stack-wrapper">
              {items.map(item => {
                return <StackItem key={item.id} name={item.name} img={item.img} theme={theme} />;
              })}
            </div>
          </div>
          <div className="stack-section">
            <h2>Mes derniers projets</h2>
            <Carousel style={{ height: 350 }} autoplay={true}>
              {projects.map(project => {
                const { id, slug, title, description, img } = project;
                return (
                  <Slide
                    key={id}
                    slug={slug}
                    title={title}
                    description={description}
                    img={img}
                    theme={theme}
                  />
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
      <style jsx>{`
        strong {
          color: ${theme.color.brand.primary};
        }
        .container {
          max-width: 900px;
          margin: auto;
          padding: ${theme.space.inset.l} ${theme.space.inset.default};
        }
        .text-intro {
          font-size: ${theme.font.size.s};
          text-align: start;
          flex: 1;
          padding: 18px 30px 18px 0;
        }
        .text-link {
          background-image: linear-gradient(
            transparent 0%,
            transparent calc(50% - 8px),
            ${theme.color.brand.primaryLight} calc(50% - 8px),
            ${theme.color.brand.primaryLight} 100%
          );
          transition: background-position 120ms ease-in-out, padding 120ms ease-in-out;
          background-size: 100% 200%;
          background-position: 0 0;
        }
        h2 {
          margin-bottom: ${theme.space.inset.l};
          font-size: ${theme.font.size.xxl};
          font-weight: ${theme.font.weight.bold};
        }
        .stack-section {
          padding: ${theme.space.inset.l} 0;
        }
        .stack-wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0 -10px;
        }
        .ant-carousel .slick-dots li {
          background-color: ${theme.color.neutral.gray.e};
        }
        .ant-carousel .slick-dots li.slick-active button {
          background-color: ${theme.color.neutral.gray.j};
        }
      `}</style>
    </React.Fragment>
  );
};

export default AboutSection;
