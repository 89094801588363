export const items = [
  {
    id: 1,
    name: "JavaScript",
    img: "js"
  },
  {
    id: 2,
    name: "React",
    img: "react"
  },
  {
    id: 3,
    name: "Node",
    img: "node"
  },
  {
    id: 4,
    name: "Express",
    img: "expressjs"
  },
  {
    id: 5,
    name: "MongoDB",
    img: "mongodb"
  },
  {
    id: 6,
    name: "Heroku",
    img: "heroku"
  },
  {
    id: 7,
    name: "Gatsby Js",
    img: "gatsbyjs"
  },
  {
    id: 8,
    name: "Bootstrap",
    img: "bootstrap"
  },
  {
    id: 9,
    name: "Git",
    img: "git"
  },
  {
    id: 10,
    name: "Sass",
    img: "sass"
  },
  {
    id: 11,
    name: "React Native",
    img: "react"
  },
  {
    id: 12,
    name: "GraphQL",
    img: "graphql"
  }
];
