import React from "react";
import PropTypes from "prop-types";

const StackItem = ({ name, img, theme }) => {
  return (
    <React.Fragment>
      <div className="stack-item">
        <img className="stack-img" src={`/images/${img}.svg`} alt={name} />
        <span className="stack-name">{name}</span>
      </div>
      <style jsx>{`
        .stack-item {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px;
          margin: 6px;
          border: 1px solid ${theme.color.neutral.gray.c};
          border-radius: 5px;
        }
        .stack-item:hover {
          background: ${theme.color.neutral.gray.a};
        }
        .stack-img {
          flex: 1;
          width: 50px;
          margin-right: 18px;
        }
        .stack-name {
          flex: 2;
          font-size: 14px;
        }

        @from-width tablet {
          .stack-item {
            max-width: 33%;
          }
          .stack-name {
            font-size: 24px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

StackItem.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default StackItem;
