export const projects = [
  {
    id: 1,
    slug: "bubblz",
    title: "Bubblz - Refonte du site web",
    description:
      "Bubblz est une solution Saas qui permet aux professionnels d'optimiser leurs processus métiers. J'ai développé ce site web avec le framework Gatsby Js, le générateur de sites basé sur React.",
    img: "bubblz-desktop"
  },
  {
    id: 2,
    slug: "laperle",
    title: "La Perle Events - Site web",
    description:
      "La Perle Events est une agence de wedding planning qui propose à ses clients des services d'organisation de mariages et d'événements à Marrakech.",
    img: "laperleevents"
  }
];
