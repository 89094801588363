/* eslint-disable react/prop-types */
import React from "react";
import { Button } from "antd";
import { Link } from "gatsby";

const Slide = ({ theme, slug, title, description, img }) => {
  return (
    <React.Fragment>
      <div className="project-slide">
        <div className="project-text">
          <h3 className="project-title">{title}</h3>
          <p>{description}</p>
          <Link to={`/projects/${slug}`}>
            <Button style={{ marginTop: 20 }} type="primary">
              En savoir plus
            </Button>
          </Link>
        </div>
        <img className="project-image" src={`/images/${img}.svg`} alt={title} />
      </div>
      <style jsx>{`
        .project-slide {
          display: flex !important;
          justify-content: space-between;
          flex-direction: column-reverse;
        }
        .project-text {
          position: relative;
          overflow: hidden;
          overflow-wrap: break-word;
          width: 100%;
          min-height: 300px;
          background-color: ${theme.color.neutral.gray.b};
          border-radius: 5px;
          padding: 30px;
        }
        .project-title {
          margin-bottom: 15px;
        }
        .project-image {
          width: 100%;
          margin-bottom: 10px;
        }

        @from-width tablet {
          .project-slide {
            flex-direction: row;
          }
          .project-image,
          .project-text {
            width: 48%;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default Slide;
